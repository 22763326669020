<template>
  <div class="main">
    <div class="head">
      文档详情
    </div>
    <el-table
      :data="list"
      border
      fit
      highlight-current-row
      width="100%"
      ref="table">

      <el-table-column label="所属客户" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.customer_name}}</span>
        </template>
      </el-table-column>

      <el-table-column label="所属项目" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.project_name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="文档标题" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.title }}</span>
        </template>
      </el-table-column>

      <el-table-column label="内容" align="center">
        <template slot-scope="scope">
        <span class="content" @click="handleDetail(scope.row)">
          <i class="el-icon-document"></i>
        </span>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.create_time }}</span>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
  import { getList } from '@/api/lists'

  export default {
    data () {
      return {
        dialogVisible: false,
        list: [],
        dialogPath: '',
        type: '',
      }
    },
    watch: {
      $route(val) {
        this.initData()
      }
    },
    created() {
      this.initData()
    },
    methods: {
      initData() {
        let is_compete = this.$route.query.is_compete
        let key = { path: 'project_docs', key: this.$route.query.shareID }
        getList(key).then( response=> {
          this.list = response.data.data
        })
      },
      handleDetail(row) {
        // this.$router.push({
        //   path: '/no_data',
        // })
        this.$router.push({
          path: '/detail',
          query: {
            id: row.id,
            key: this.$route.query.shareID,
            isChild: true
          }
        })
      },
    }
  }
</script>

<style scoped>
  .main {
    width: 95%;
    margin: 0 auto;
    box-shadow: 0 0 5px rgba(224, 224, 224, 0.6);
    padding: 12px 20px;
    background: white;
  }
  .head {
    width: 100%;
    height: 40px;
    /*padding-left: 20px;*/
    line-height: 40px;
    font-weight: 700;
    color: #b4b4b4;
    /*text-shadow: 0.4px 1px 2px #A5A5A5;*/
    overflow: hidden;
  }
  .content {
    cursor: pointer;
    font-size: 20px;
  }
  .content:hover {
    color: #409EFF;
  }
</style>
